import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "aos/dist/aos.css"; // Import AOS styles
import AboutUs from "./AboutUs";
import Services from "./Services";
import Contact from "./Contact";
import { useState ,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    college: '',
    district: '',
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
    // Handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      
      // Store a flag in local storage to indicate form submission
      localStorage.setItem('formSubmitted', 'true');
  
      // You can also store the form data if needed
      localStorage.setItem('formData', JSON.stringify(formData));
  
      // Redirect to the challenges page
      navigate('/challenges');
      console.log(formData)
    };
  
  // Check if the user has already submitted the form
  useEffect(() => {
    const formSubmitted = localStorage.getItem('formSubmitted');
    if (formSubmitted) {
      navigate('/'); // Redirect to challenges page if form is already submitted
    }
  }, [navigate]);
  return (
    <>
      <section className="hero-section">
        <Container
          fluid
          className="d-flex align-items-center justify-content-center pt-5"
        >
          <Row className="w-100 align-items-center">
            <Col md={6} className="text-white text-center text-md-left p-5">
              <h1
                className="hero-title sm-display-6"
                data-aos="fade-right" // AOS animation type
              >
                Your Digital Future Starts Here
              </h1>
              <p
                className="hero-subtitle"
                data-aos="fade-right"
                data-aos-delay="200" // Delay for the subtitle
              >
                Innovative web solutions tailored for your business success.
              </p>
              <Button
                variant="warning"
                size="lg"
                className="hero-btn"
                data-aos="fade-right"
                data-aos-delay="400"
                onClick={handleShow}
              >
                Take Challenges
              </Button>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title className="">Ready to Begin</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>

                <Modal.Body>
                  
                <img
                  src={`${process.env.PUBLIC_URL}/images/loginscreen.gif`}
                  alt="Web Solutions"
                  className="img-fluid"
                />
                   
                </Modal.Body>
                <Modal.Footer >
                
                  <Button variant="primary" onClick={()=>navigate('/login')}>
                   Login
                  </Button>
                
                </Modal.Footer>
                </form>

              </Modal> 

            </Col>
            <Col md={6}>
              <div
                className="hero-gif"
                data-aos="fade-left" // GIF animation type
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/hero.png`}
                  alt="Web Solutions"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <>
        <AboutUs />
        <Services />
        <Contact />
      </>
    </>
  );
};

export default Hero;
