// src/services/userService.js
export const registerUser = (user) => {
    const users = JSON.parse(localStorage.getItem('users')) || [];
    const existingUser = users.find(u => u.email === user.email);
  
    if (existingUser) {
      throw new Error('User already exists');
    }
  
    users.push(user);
    localStorage.setItem('users', JSON.stringify(users));
  };
  
  export const loginUser = (email, password) => {
    const users = JSON.parse(localStorage.getItem('users')) || [];
    return users.find(user => user.email === email && user.password === password);
  };
  
  