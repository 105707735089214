// Dashboard.js
import {Container,Col,Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

function Dashboard({ onLogout }) {

  const email = localStorage.getItem('userEmail')
  return (
    <div>

          <div>
          <Col md={9}>
            <div className="p-3">
              <h4>Welcome, {email}!</h4>
              <p className="mt-4">
                Here you can see your challenges and manage your progress.
              </p>
              <Button variant="danger" onClick={onLogout}>Logout</Button>
            </div>
          </Col>
        
         </div>
       
         </div>
        
     
  );
}

export default Dashboard;
