import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"
import NavBar from './Components/NavBar';
import Hero from './Components/Hero';
import Services from './Components/Services';
import AOS from 'aos';
import { useEffect,useState } from 'react';
import AboutUs from './Components/AboutUs';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import ChallengesPage from './Components/ChallengesPage';
import { Routes ,Route, Navigate,useNavigate } from 'react-router-dom';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import Dashboard from './Components/Dashboard';
import SubmitChallenge from './Components/SubmitChallenge';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate=useNavigate()
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
    const email = localStorage.getItem('userEmail');
    if (email) {
      setIsAuthenticated(true);
    }
  }, []);
  const handleSignup = (email, password) => {
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userPassword', password);
    setIsAuthenticated(true);
  };
  const handleLogin = (email) => {
    localStorage.setItem('userEmail', email);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPassword');
    setIsAuthenticated(false);
    console.log("Logout")
    navigate('/')
  };
 
  return (
    <div>
      <NavBar></NavBar>
      <Routes>
    <Route path="/" element={<Hero />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/challenge" element={<ChallengesPage onLogout={handleLogout}/>} /> 
        <Route path="/submit" element={<SubmitChallenge/>} /> 
        <Route path="/login"  element={isAuthenticated ? <Navigate to="/challenge" /> : <Login onLogin={handleLogin} />} /> 
        <Route path="/signup"  element={isAuthenticated ? <Navigate to="/challenge" /> : <SignUp onSignup={handleSignup}/>}/>
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard onLogout={handleLogout} /> : <Navigate to="/" />}
        />
      </Routes>
<Footer/>
    </div>
  );
}

export default App;
