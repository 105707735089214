import {React,useState} from 'react'
import { Modal,Col,Form,Row,Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';


const SubmitChallenge = () => {
    const navigate = useNavigate();
 
  const [showModal, setShowModal] = useState(true);
  const [challengeLink, setChallengeLink] = useState('');
  const handleSubmitLink = () => {
    // Process the challenge link here
    console.log('Challenge link submitted:', challengeLink);
    setChallengeLink('');
    handleClose(); // Close modal after submission
  };
  const handleClose = () => {
    setShowModal(false);
navigate('/challenge')
  }
  const handleShow = () => setShowModal(true);
  return (

    <Modal show={showModal} onHide={handleClose} fullscreen>
              <Modal.Header closeButton>
                <Modal.Title className='text-primary'>Submit Challenge Links</Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
                <Form className='container'>
                  <Row>
                  <Col md={6}>
                      <Form.Group controlId="formChallengeLink1">
                        <Form.Label className='mt-3'>Name (For Certificate) <span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                        
                      </Form.Group>
                      <Form.Group controlId="formChallengeLink1">
                        <Form.Label className='mt-3'>Email<span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your Email Id to get certified"
                        />
                      </Form.Group>
                        </Col>
                  </Row>
                  <div className='border border-1 border-warning p-4 mt-3'>
                    <h5>Github Links</h5>
                  <Row >
                    <Col md={6}>
                    
                      <Form.Group controlId="formChallengeLink1">
                        <Form.Label className='mt-3'>Challenge Link 1 <span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="input-line"
                          placeholder="Enter the demo link"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formChallengeLink2">
                        <Form.Label className='mt-3'>Challenge Link 2 <span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="input-line"
                          placeholder="Enter the demo link"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Form.Group controlId="formChallengeLink3">
                        <Form.Label>Challenge Link 3 <span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="input-line"
                          placeholder="Enter the demo link"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formChallengeLink4">
                        <Form.Label>Challenge Link 4 <span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="input-line"
                          placeholder="Enter the demo link"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Form.Group controlId="formChallengeLink5">
                        <Form.Label>Challenge Link 5 <span className="text-danger">*</span></Form.Label>
                        <input
                          type="text"
                          className="input-line"
                          placeholder="Enter the demo link"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                    </div>
                 
                 
                     
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmitLink}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
  )
}

export default SubmitChallenge
