import React, { useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ChallengesPage({onLogout}) {
  const navigate = useNavigate();
  const email = localStorage.getItem("userEmail");
  const [selectedChallenge, setSelectedChallenge] = useState(null);

  // Sample data for challenges
  const challenges = [
    {
      id: 1,
      title: "Challenge 1",
      description: "Ecommerce Website",
      file: "/documents/Challenge1-E-Commerce.pdf",
      src: "ecommerce.jpg",
    },
    {
      id: 2,
      title: "Challenge 2",
      description: "Blog Website",
      file: "/documents/Challenge2-Blog.pdf",
      src: "Blog.jpg",
    },
    {
      id: 3,
      title: "Challenge 3",
      description: "Portfolio Website",
      file: "/documents/Challenge3-Portfolio.pdf",
      src: "Portfolio.jpg",
    },
    {
      id: 4,
      title: "Challenge 4",
      description: "Educational Website",
      file: "/documents/Challenge4-Education.pdf",
      src: "education.jpg",
    },
    {
      id: 5,
      title: "Challenge 5",
      description: "Resturant Website",
      file: "/documents/Challenge5-Restaurant.pdf",
      src: "resturant.jpg",
    },
  ];

  const handleChallengeClick = (challenge) => {
    setSelectedChallenge(challenge);
  };

  const handleClose = () => setSelectedChallenge(null);

  return (
    <section className="dashboard">
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <Col md={3} className="sidebar">
            <h3 className="sidebar-title">
              Dashboard <i className="bi bi-window-dash"></i>
            </h3>
            <ul className="sidebar-nav">
              <li>
                <Button variant="link" onClick={onLogout}>
                  <i className="bi bi-box-arrow-left"></i> Logout
                </Button>
              </li>
              <li>
                <Button variant="link" onClick={() => navigate("/challenge")}>
                  <i className="bi bi-clipboard2-data-fill"></i> Challenges
                </Button>
              </li>
              <li>
                <Button variant="link" onClick={() => navigate("/submit")}>
                  <i className="bi bi-clipboard2-data-fill"></i> Submit
                </Button>
              </li>
            </ul>
          </Col>

          {/* Main Content */}
          <Col md={9} className="main-content p-4">
            <h1 className="page-title mb-4">Welcome, {email}!</h1>
            <Row className="mb-4">
              <Col>
                <h2 className="page-subtitle">Download Your Challenges</h2>
                <p className="page-description">
                  Browse through the challenges below and download the ones that
                  interest you.
                </p>
              </Col>
            </Row>
            <Row>
              {challenges.map((challenge) => (
               <Col md={6} key={challenge.id} className="mb-4">
               <div className="card challenge-card">
                 <div className="card-body">
                   <h3 className="card-title challenge-title">{challenge.title}</h3>
                   <p className="card-text challenge-description">
                     {challenge.description}
                   </p>
                   <div className=" d-flex justify-content-between mt-3" role="group">
                     <Button
                       variant="primary"
                       onClick={() => handleChallengeClick(challenge)}
                     >
                       View <i className="bi bi-eye-fill"></i>
                     </Button>
                     <Button
                       variant="warning"
                       href={challenge.file}
                       download
                       className="ml-2"
                     >
                       Download <i className="bi bi-download"></i>
                     </Button>
                   </div>
                 </div>
               </div>
             </Col>
             
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Modal for Viewing Challenge */}
      {selectedChallenge && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {selectedChallenge.description}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={`${process.env.PUBLIC_URL}/images/${selectedChallenge.src}`}
              alt={selectedChallenge.description}
              className="img-fluid"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
}

export default ChallengesPage;
