import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <Navbar expand="lg" className=" sticky-top  navbar navbar-light bg-light">
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            height={50}
            width={90}
            alt="logo"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll "  />
        <Navbar.Collapse id="navbarScroll ">
          <Nav
            className="ms-auto"
            style={{ maxHeight: "150px" ,fontSize:'25px' , fontFamily:'inherit',fontWeight:'bold'}}
            navbarScroll
          >
          <Link to="/" className="link-style"><Nav.Link href="#action1"> Home</Nav.Link> </Link>  
            <Link to="/about" className="link-style"><Nav.Link href="#about-section">AboutUs</Nav.Link></Link>
            <Link to="/services" className="link-style"><Nav.Link href="#services-page">Services</Nav.Link></Link>
            <Link to="/contact" className="link-style"><Nav.Link href="#action4">Contact</Nav.Link></Link>
            <Link to="/login" className="link-style"><Nav.Link href="#action4"><i class="bi bi-person-bounding-box"></i></Nav.Link></Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
